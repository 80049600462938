import React from 'react'
import './images-swiper-a7.css'
import Swiper from 'swiper'
import PropTypes from 'prop-types'


export default class ImagesSwiperA7 extends React.Component {
  static propTypes={
    imageUrls:PropTypes.array.isRequired
  }
  componentDidMount() {
    var swiperImages = new Swiper('.images-a7', {
      slidesPerView: 3,
      spaceBetween: 20,
      centeredSlides: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }

    })
  }

  render(){
    // const a2=this.props.imageUrls.map((image,index)=><div key={index} className="swiper-slide" style={{backgroundImage:`url(${require("../a2/3.png")})`}} ></div>)

    const images=this.props.imageUrls.map((image,index)=><div key={index} className="swiper-slide" style={{backgroundImage:'url(' +image + ')'}} ></div>)
    return (
      <div className="images-container-a7">
        <div className="swiper-container images-a7">
          <div className="swiper-wrapper">
            {images}
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    )
  }


}